import './Footer.css'

type FooterProps = {
  showTitle: string
  supportEmail: string
  copyrightOwner: string
  supportMailtoHref: string
}

export const Footer = (props: FooterProps) => {
  const currentYear = new Date().getFullYear()

  return <>
    <footer>
      <div className="footer">
        <small>&copy; {props.copyrightOwner}, {currentYear} | Problems? Contact <a href={props.supportMailtoHref}>{props.supportEmail}</a></small>
      </div>
    </footer>
  </>;
};
