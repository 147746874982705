import { useEffect, useState } from 'react';
import { Stream } from "@cloudflare/stream-react"

import './VideoPanel.css'

import lockIconClosed from './assets/lock-closed.svg'
import lockIconOpen from './assets/lock-open.svg'
import { StreamWindowState } from './App';

type VideoPanelProps = {
  isLoggedIn: boolean
  streamAccessAuthorized: boolean
  countdownString: string
  setShowTicketCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  ticketPurchaseUrl: string
  heroImg: string
  streamWindowState: StreamWindowState
}

export const VideoPanel = (props: VideoPanelProps) => {

  // Desired behavior: lock image displays as locked
  const [lockImageIsLocked, setLockImageIsLocked] = useState<boolean>( !props.isLoggedIn )

  useEffect(() => {
    setLockImageIsLocked( !props.isLoggedIn )
  }, [props.isLoggedIn])

  const showTicketCodeModal = () => props.setShowTicketCodeModal(true)

  const info = <>
    <div className="video-panel-text-container">
      Stream available {props.streamWindowState === 'pre-open' ? "in" : "for the next"}:
      <br />
      {props.countdownString}
    </div>
    { lockImageIsLocked
      ? <img className="lock-icon" alt="locked" src={lockIconClosed} />
      : <img className="lock-icon" alt="unlocked" src={lockIconOpen} />
    }
    <div
      className="video-panel-text-container"
      onMouseEnter={ ()=> !props.isLoggedIn && setLockImageIsLocked(false) }
      onMouseLeave={ ()=> !props.isLoggedIn && setLockImageIsLocked(true) }
    >
      { props.isLoggedIn
        ? "Ticket code validated"
        : <>
          <a href={props.ticketPurchaseUrl} target="_blank">Buy tickets</a>
          <a href="#" onClick={showTicketCodeModal} >I have a ticket code</a>
        </>
      }
    </div>
  </>

  const videoId = 'df2a254c56dbe017dc3558f3ced9e351'
  const video = <>
    <Stream
      src={videoId}
      autoplay={true}
      controls={true}
    />
  </>

  const postWindow = <>
    <div className="video-panel-text-container">
      Thanks for watching
    </div>
  </>

  let currentView: JSX.Element
  if (props.streamWindowState === 'open' && props.streamAccessAuthorized) {
    currentView = video
  } else if (props.streamWindowState === 'post-open') {
    currentView = postWindow
  } else {
    currentView = info
  }

  return <>
    <div
      className="video-panel"
      style={{ backgroundImage: `url(${props.heroImg})` }}
    >
      { currentView }
    </div>
  </>;
};