import { StreamWindowState } from './App';
import './InfoPanel.css'

type InfoPanelProps = {
  noStreamAccessInfo: JSX.Element
  postStreamInfo: JSX.Element
  showNotesInfo: JSX.Element
  streamWindowState: StreamWindowState
  isLoggedIn: boolean
  streamAccessAuthorized: boolean
  ticketPurchaseUrl: string
}

export const InfoPanel = (props: InfoPanelProps) => {
  if (props.streamAccessAuthorized) {
    return <>
      <div className="info-panel">
        {props.showNotesInfo}
      </div>
    </>
  } else if (props.streamWindowState === 'post-open') {
    return <>
      <div className="info-panel">
        {props.postStreamInfo}
      </div>
    </>
  } else {
    return <>
      <div className="info-panel">
        {props.noStreamAccessInfo}
      </div>
    </>
  }
};
