const extendedEnv = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_SHOW_TITLE: process.env.REACT_APP_SHOW_TITLE as string,
  REACT_APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME as string,
};

if (process.env.NODE_ENV !== 'production') {
  for (const [key, value] of Object.entries(extendedEnv)) {
    console.log(`${key}: ${value}`)
    //@ts-expect-error
    if (extendedEnv[key] === undefined) {
      throw new Error(`Missing env variable: ${key}`)
    }
  }
}

export default extendedEnv