import { StreamWindowState } from './App';
import './Header.css'

type HeaderProps = {
  logo: string
  isLoggedIn: boolean
  streamWindowState: StreamWindowState
  // userName: string | undefined
  ticketCode: string | undefined
  companyName: string
  countdownString: string
  showTitle: string
  mainSiteUrl: string
  doLogout: React.MouseEventHandler<HTMLAnchorElement>
}

export const Header = (props: HeaderProps) => {
  return <>
    <header>
      <div className="header">
        <div className="logo">
          <img src={props.logo} className="logo-img" alt="" />
        </div>
        <div className="title-text">
          <div className="show-title">
            <h1 className="header-link">
              <a href={props.mainSiteUrl}>
                {props.showTitle}
              </a>
            </h1>
          </div>
          <div className="company-name">
            <h2 className="header-link">
              <a href={props.mainSiteUrl}>
                {props.companyName}
              </a>
            </h2>
          </div>
        </div>
        { props.isLoggedIn && props.streamWindowState !== 'post-open' &&
          <div className="ticket-info push-right" >
            {/* <p>
              Welcome, {props.userName}!
              <br />
              (<a href="#" onClick={ props.doLogout }>Not you?</a>)
            </p>
            <p>Ticket #{props.ticketCode}</p> */}
            <p>
              Ticket #{props.ticketCode}
              <br />
              (<a href="#" onClick={ props.doLogout }>Not you?</a>)
            </p>
            <p>Stream available {props.streamWindowState === 'pre-open' ? "in" : "for the next"}:<br />{props.countdownString}</p>
          </div>
        }
      </div>
    </header>
  </>;
};
