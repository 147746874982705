import { Modal } from "react-bootstrap";
import { ChangeEvent, FormEvent, useState } from "react";

import './TicketCodeModal.css'

import {
  useSearchParams
} from "react-router-dom";

type TicketCodeModalProps = {
  showTicketCodeModal: boolean
  setShowTicketCodeModal: React.Dispatch<React.SetStateAction<boolean>>
  supportEmail: string
  ticketNotificationEmailSubject: string
  ticketPurchaseUrl: string
  supportMailtoHref: string
  validateTicketCode: (ticketCode: string) => Promise<void>
  ticketCode: string | undefined
}

export const TicketCodeModal = (props: TicketCodeModalProps) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const ticketCode = searchParams.get('ticketCode')
  if (ticketCode && ticketCode !== props.ticketCode) {
    props.validateTicketCode(ticketCode)
  }

  const [showCodeNotRecognized, setShowCodeNotRecognized] = useState<boolean>(false)
  const [showCantFindCodeBody, setShowCantFindCodeBody] = useState<boolean>(false)
  const [codeReminderEmailAddress, setCodeReminderEmailAddress] = useState<string|undefined>()
  const [codeReminderEmailSent, setCodeReminderEmailSent] = useState<boolean>(false)

  const [codeInput, setCodeInput] = useState<string>("")
  const [codeIsValid, setCodeIsValid] = useState<boolean|undefined>()

  const doShowCantFindCodeBody = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setShowCantFindCodeBody(true)
  }

  const hideCantFindCodeBody = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setShowCantFindCodeBody(false)
  }

  const hideModal = () => {
    setShowCantFindCodeBody(false)
    props.setShowTicketCodeModal(false)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCodeInput(event.target.value);
  }

  const handleCodeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    console.log("Code input", codeInput)
    await props.validateTicketCode(codeInput)
  }

  const defaultBody = <>
    <h2>Enter ticket code:</h2>
    <p className="code-error-warning" style={{ visibility: showCodeNotRecognized ? "visible" : "hidden" }}>Code not recognized</p>
    <br />
    <form onSubmit={handleCodeSubmit}>
      <input
        type="text"
        name="ticketCpde"
        value={codeInput}
        onChange={handleInputChange}
        required={true}
      />
      &nbsp;
      <input type="submit" value="Submit" />
    </form>
    <br />
    <p>Your code should be in an email from &lt;{props.supportEmail}&gt;, with the subject "{props.ticketNotificationEmailSubject}"</p>
    <p><a href="#" onClick={doShowCantFindCodeBody}>I can't find my code, or I have a problem with my code</a></p>
    <p><a href={props.ticketPurchaseUrl} target="_blank">Buy tickets</a></p>
  </>

  const cantFindCodeBody = <>
    <h2>I can't find my code</h2>
    <p>Enter the email address you used to purchase tickets on Eventbrite</p>
    {/* todo: Hook up code reminder email logic */}
    _____________
    { codeReminderEmailSent
      ? <p>If a code was purchased using &lt;{codeReminderEmailAddress}&gt;, a reminder email will be sent to that address. If you haven't received it in 5 minutes, and you already checked your spam folder, contact us at <a href={props.supportMailtoHref}>{props.supportEmail}</a>.</p>
      : <p>Problems? Contact <a href={props.supportMailtoHref}>{props.supportEmail}</a></p>
    }
    <p><a href="#" onClick={hideCantFindCodeBody}>Go back</a></p>
  </>

  return <>
    <Modal
      dialogClassName="ticket-code-modal"
      size="lg"
      show={props.showTicketCodeModal}
      scrollable={true}
      onHide={ hideModal }
    >
      <Modal.Header closeButton />
      <Modal.Body>
        { showCantFindCodeBody
          ? cantFindCodeBody
          : defaultBody
        }
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  </>;
};
