import '@fontsource/playfair-display'

import { useEffect, useState } from 'react'

import {
  BrowserRouter as Router,
} from "react-router-dom";

import extendedEnv from './env'
import './App.css'
import { Footer } from './Footer'
import { Header } from './Header'
import { InfoPanel } from './InfoPanel'
import { TicketCodeModal } from './TicketCodeModal'
import { VideoPanel } from './VideoPanel'

import logo from './assets/favicon-src.png'
import heroImg from './assets/hero.png'

type TimeWindow = {
  beginAt: Date
  endAt: Date
}

export type StreamWindowState =
  | 'pre-open'
  | 'open'
  | 'post-open'

const App = () => {
  const companyName = extendedEnv.REACT_APP_COMPANY_NAME
  const showTitle = extendedEnv.REACT_APP_SHOW_TITLE

  // Edit these:
  const mainSiteUrl = "https://www.embodydanceproject.org/"
  const copyrightOwner = "Evan Johnston"
  const supportEmail = "support@embodydanceproject.org"
  const supportMailtoHref = `mailto:${supportEmail}?subject=${showTitle} support`
  const ticketNotificationEmailSubject = `Your ticket for ${showTitle}`
  const ticketPurchaseUrl = "https://www.eventbrite.com/e/elements-tickets-191823969657"
  const facebookUrl = "https://www.facebook.com/Embody-dance-project-110186714193877/"
  const instagramUrl = "https://www.instagram.com/embodydanceproject/"
  const patreonUrl = "https://www.patreon.com/embodydanceproject"
  // const ticketCodeValidationUrl = "https://embody-video-platform-backend.embodydanceproject.workers.dev/ticketIsValid"
  // const resendTicketCodeUrl = "https://embody-video-platform-backend.embodydanceproject.workers.dev/resendTicket"

  const actualStreamWindow: TimeWindow = {
    beginAt: new Date('2021-11-20T03:00:00Z'),
    endAt: new Date('2021-12-01T07:59:00Z')
  }

  const durationStreamAvailableAfterStatedWindow = 2 * 24 * 60 * 60 * 1000  // 2 days in milliseconds
  const displayedStreamWindow: TimeWindow = {
    beginAt: actualStreamWindow.beginAt,
    endAt: new Date( actualStreamWindow.endAt.valueOf() - durationStreamAvailableAfterStatedWindow )
  }

  const dateToLocaleStringOptions = {
    year: 'numeric' as 'numeric',
    month: 'long' as 'long',
    day: 'numeric' as 'numeric'
  }

  const timeToLocaleStringOptions = {
    hour: 'numeric' as 'numeric',
    minute: 'numeric' as 'numeric'
  }

  const streamWindowBeginAtString = displayedStreamWindow.beginAt.toLocaleString([], timeToLocaleStringOptions)
    + ' on '
    + displayedStreamWindow.beginAt.toLocaleString([], dateToLocaleStringOptions)

  const streamWindowEndAtString = displayedStreamWindow.endAt.toLocaleString([], timeToLocaleStringOptions)
  + ' on '
  + displayedStreamWindow.endAt.toLocaleString([], dateToLocaleStringOptions)

  const [showTicketCodeModal, setShowTicketCodeModal] = useState<boolean>(false)  // default false
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false) // default false
  const [streamWindowState, setStreamWindowState] = useState<StreamWindowState>('pre-open') // default 'pre-open'
  const [streamAccessAuthorized, setStreamAccessAuthorized] = useState<boolean>(isLoggedIn && streamWindowState === 'open')  // default false
  // const [userName, setUserName] = useState<string|undefined>()  // default undefined
  const [ticketCode, setTicketCode] = useState<string|undefined>()  // default undefined
  const [countdownString, setCountdownString] = useState<string>("") // default ""

  useEffect(() => {
    setStreamAccessAuthorized(isLoggedIn && streamWindowState === 'open')
  }, [isLoggedIn, streamWindowState])

  setInterval(() => {
    const now = new Date()

    const desiredStreamWindowState: StreamWindowState = (now < actualStreamWindow.beginAt)
      ? 'pre-open'
      : (now > actualStreamWindow.endAt)
        ? 'post-open'
        : 'open';
    if (desiredStreamWindowState !== streamWindowState) {
      setStreamWindowState(desiredStreamWindowState)
    }

    let timeRemainingInState: number // milliseconds
    if (now < actualStreamWindow.beginAt) {
      // Before stream window
      timeRemainingInState = actualStreamWindow.beginAt.valueOf() - now.valueOf()
    } else if (now < displayedStreamWindow.endAt) {
      // During regular time of stream window
      timeRemainingInState = displayedStreamWindow.endAt.valueOf() - now.valueOf()
    } else if (now < actualStreamWindow.endAt) {
      // During "bonus" time of stream window
      timeRemainingInState = actualStreamWindow.endAt.valueOf() - now.valueOf()
    } else {
      // After stream window (and edge cases)
      timeRemainingInState = 0
    }

    const days = Math.floor(timeRemainingInState / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemainingInState % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemainingInState % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemainingInState % (1000 * 60)) / 1000);

    const newCountdownString =
      ((days > 0) ? `${days} day${days === 1 ? "" : "s"}, ` : "")
      + ((days > 0 || hours > 0) ? `${hours} hour${hours === 1 ? "" : "s"}, ` : "")
      + ((days > 0 || hours > 0 || minutes > 0) ? `${minutes} minute${minutes === 1 ? "" : "s"}, and ` : "")
      + (`${seconds} second${seconds === 1 ? "" : "s"}`);

    if (newCountdownString !== countdownString) {
      setCountdownString(newCountdownString)
    }
  }, 1000)

  const validateTicketCode = async (ticketCode: string) => {
    // const payload = { ticketId: ticketCode }
    // const resp = await fetch(ticketCodeValidationUrl, {
    //   method: 'POST',
    //   body: JSON.stringify(payload)
    // })
    // const respBody = await resp.json()
    // if (resp.ok) {
    //   console.log(respBody)
    // }
    setTicketCode(ticketCode)
    setIsLoggedIn(true)
    setShowTicketCodeModal(false)
  }

  const noStreamAccessInfo = <>
    <p>
      Rounding out our third season, Elements is em(body) Dance Project’s second virtual full length program in collaboration with Jones Pro Photo and Film. With works by choreographers Sasha Gologorskaya, Patience Gordon, Vinnie Jones, and Naomi Sailors, Elements explores the emotions that are the building blocks of human nature.
    </p>
    <p>
      { streamWindowState === 'pre-open'
        ? `It will be available to stream from ${streamWindowBeginAtString} until ${streamWindowEndAtString}.`
        : `It will be available to stream until ${streamWindowEndAtString}.`
      }
    </p>
    { !isLoggedIn &&
      <p style={{ textAlign: "center" }}>
        <a href={ticketPurchaseUrl}>Buy tickets</a>
      </p>
    }
  </>

  const postStreamInfo = <>
    <p>
      {showTitle} is no longer available for streaming. We hope you enjoyed the show!
    </p>
    <p>
      Follow us on <a href={facebookUrl} target="_blank" rel="noreferrer">Facebook</a>, <a href={instagramUrl} target="_blank" rel="noreferrer">Instagram</a>, and <a href={patreonUrl} target="_blank" rel="noreferrer">Patreon</a> for behind the scenes footage and updates on future shows.
    </p>
  </>

  const showNotesInfo = <>
    <h3>Program Notes</h3>
    <div className="show-note-container">

      <div className="show-note-piece-container">
        <h4>Water Stories</h4>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Choreography</h5></div>
          <div className="show-note-item-content">Sasha Gologorskaya in collaboration with the dancers</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Music</h5></div>
          <div className="show-note-item-content">Excerpts from <cite>Red Slip</cite> composed by Tom Rogerson & Brian Eno, <cite>Slipstream</cite> composed by Jon Hassell, <cite>A Delicate Romance</cite> composed by John Foxx & Harold Budd, and <cite>Heol</cite> composed by Yann Tiersen</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Costumes</h5></div>
          <div className="show-note-item-content">Sasha Gologorskaya and Gail Baldoni</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Dancers</h5></div>
          <div className="show-note-item-content">Camille Barlow, Evan Johnston, Vinnie Jones, Robert Lowman, Naomi Sailors, and Fabiana Santiago</div>
        </div>
      </div>

      <div className="show-note-piece-container">
        <h4>Amygdala</h4>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Choreography</h5></div>
          <div className="show-note-item-content">Vinnie Jones in collaboration with the dancers</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Music</h5></div>
          <div className="show-note-item-content">Moments, Joshua Spacht, and Patrick Rydman</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Dancers</h5></div>
          <div className="show-note-item-content">Patience Gordon, Robert Lowman, and Naomi Sailors</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Note</h5></div>
          <div className="show-note-item-content">Special thank you to the dancers of Pacific Ballet Academy for their ideas and collaboration. </div>
        </div>
      </div>

      <div className="show-note-piece-container">
        <h4>Five minute intermission</h4>
      </div>

      <div className="show-note-piece-container">
        <h4>La Spagna Variations</h4>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Choreography</h5></div>
          <div className="show-note-item-content">Naomi Sailors</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Music</h5></div>
          <div className="show-note-item-content">Francesco da Milano and Various Artists</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Dancers</h5></div>
          <div className="show-note-item-content">Camille Barlow, Sasha Gologorskaya, Patience Gordon, Evan Johnston, Vinnie Jones, Robert Lowman, and Fabiana Santiago</div>
        </div>
      </div>

      <div className="show-note-piece-container">
        <h4>At Once</h4>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Choreography</h5></div>
          <div className="show-note-item-content">Patience Gordon</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Music</h5></div>
          <div className="show-note-item-content">Olafur Arnalds, Roberto Cacciapaglia, David Darling, Ezio Bosso, and Nick Box</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Dancers</h5></div>
          <div className="show-note-item-content">Camille Barlow, Sasha Gologorskaya, Vinnie Jones, Robert Lowman, Naomi Sailors, and Fabiana Santiago</div>
        </div>
        <div className="show-note-item-container">
          <div className="show-note-item-title"><h5>Note</h5></div>
          <div className="show-note-item-content">Dedicated to my students, the original cast, who brought this vision to life. </div>
        </div>
      </div>

      <div>
        <br />
        <br />
        <p>
          em(body) Dance Project would like to thank Little Boxes Theater for hosting and assisting in the filming of Elements. A very special thank you to Jones Pro Photo and Film, who generously shared their time and expertise filming and editing this production.
        </p>
      </div>

    </div>
  </>

  const doLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setIsLoggedIn(false)
  }

  return (
    <Router>
      <div className="App">
        <Header
          logo={logo}
          companyName={companyName}
          showTitle={showTitle}
          mainSiteUrl={mainSiteUrl}
          isLoggedIn={isLoggedIn}
          // userName={userName}
          ticketCode={ticketCode}
          doLogout={doLogout}
          countdownString={countdownString}
          streamWindowState={streamWindowState}
        />
        <TicketCodeModal
          showTicketCodeModal={showTicketCodeModal}
          setShowTicketCodeModal={setShowTicketCodeModal}
          supportEmail={supportEmail}
          supportMailtoHref={supportMailtoHref}
          ticketNotificationEmailSubject={ticketNotificationEmailSubject}
          ticketPurchaseUrl={ticketPurchaseUrl}
          validateTicketCode={validateTicketCode}
          ticketCode={ticketCode}
        />
        <VideoPanel
          isLoggedIn={isLoggedIn}
          streamAccessAuthorized={streamAccessAuthorized}
          countdownString={countdownString}
          setShowTicketCodeModal={setShowTicketCodeModal}
          ticketPurchaseUrl={ticketPurchaseUrl}
          heroImg={heroImg}
          streamWindowState={streamWindowState}
        />
        <InfoPanel
          isLoggedIn={isLoggedIn}
          streamWindowState={streamWindowState}
          streamAccessAuthorized={streamAccessAuthorized}
          ticketPurchaseUrl={ticketPurchaseUrl}
          noStreamAccessInfo={noStreamAccessInfo}
          showNotesInfo={showNotesInfo}
          postStreamInfo={postStreamInfo}
        />
        <Footer
          showTitle={showTitle}
          copyrightOwner={copyrightOwner}
          supportEmail={supportEmail}
          supportMailtoHref={supportMailtoHref}
        />
      </div>
    </Router>
  );
}

export default App;
